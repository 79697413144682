import Container from "./components/Container";

function App() {
  return (
    <div  className="w-full h-full min-h-screen bg-black flex justify-center items-center">
      <Container />
    </div>
  );
}

export default App;
