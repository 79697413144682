import React, { useRef, useState } from "react";

const Container = () => {


  const videoRef = useRef(null);
  const videoList = ['/a_letter_kr.mp4', '/a_letter_eng.mp4', '/b_letter_kr.mp4', '/b_letter_eng.mp4'];
const [videoFile, setVideoFile] = useState(videoList[0]);
  const [width, setWidth] = React.useState(window.innerWidth);

  const [height, setHeight] = React.useState(window.innerHeight);

  //const [isMuted, setIsMuted] = useState(true); // muted 상태를 로컬 상태로 관리

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handleTogglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };
  // const handlePlayBackgroundSound = () => {
  //   if (videoRef.current) {
  //     const newMutedState = !videoRef.current.muted;
  //     videoRef.current.muted = newMutedState; // 비디오의 muted 상태 변경
  //     setIsMuted(newMutedState); // 로컬 상태 업데이트
  //   }
  // }

  const startTime = 20; // 반복 시작 시간
  const endTime = 39.7; // 반복 끝 시간

  React.useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      if (video.currentTime >= endTime) {
        video.currentTime = startTime;
      }
    };
    // 이벤트 리스너 추가
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };

  }, []);

  return (
    <div
      className={` relative w-fit h-fit flex ${width < 688 ? 'flex-col': ''} justify-end items-end bg-black overflow-hidden`}
    >
      <div className={`w-full flex ${width < 688 ? 'grid grid-cols-2 ': 'flex flex-col self-start '} gap-1 m-2`}>
        <button type='button' className="py-1 px-3 cursor-pointer bg-white hover:bg-slate-200 w-full max-w-[160px]" onClick={()=>setVideoFile('/a_letter_kr.mp4')}>a버전 한글</button>
        <button type='button' className="py-1 px-3 cursor-pointer bg-white hover:bg-slate-200 w-full max-w-[160px]" onClick={()=>setVideoFile('/b_letter_kr.mp4')}>b버전 한글</button>
      </div>
      {videoFile === videoList[0] ? <video
        autoPlay
        ref={videoRef}
        width={"auto"}
        loop
        muted
        playsInline
        style={{
          width: width < 688 ? `${width}px` : "auto",
          height:height > 1080 ? `${height}px` : "auto",
          maxHeight: `${height}px`,
          objectFit: "contain",
        }}
        height={1200}
        preload="auto"
      >
        <source
          src={'/a_letter_kr.mp4'}
          type="video/mp4"
          autoPlay
          muted
          loop
          controls
        />
      </video>: null}
   
      {videoFile === videoList[2] ? <video
        autoPlay
        ref={videoRef}
        width={"auto"}
        loop
        muted
        playsInline
        style={{
          width: width < 688 ? `${width}px` : "auto",
          height:height > 1080 ? `${height}px` : "auto",
          maxHeight: `${height}px`,
          objectFit: "contain",
        }}
        height={1200}
        preload="auto"
      >
        <source
          src={'/b_letter_kr.mp4'}
          type="video/mp4"
          autoPlay
          muted
          loop
          controls
        />
      </video>: null}
  
        <div
        className={`absolute z-10 p-2`}
        >
          <button onClick={handleTogglePlay} className="focus:outline-none">
        <img
          src={isPlaying ? "play_on_button.png" : "play_off_button.png"}
          alt={isPlaying ? "Pause" : "Play"}
          className={width < 688 ? 'w-8 h-8':'w-11 h-11'}
        />
      </button>
      <audio
        ref={audioRef}
        src="sound.wav"
        onEnded={handleAudioEnded}
        className="hidden"
      />
        </div>
    </div>
  );
};

export default Container;
